<template>
  <v-card
    light
    width="100%"
    max-width="450"
    max-height="450"
    height="100%"
    transition="scale-transition"
    tile
  >
    <v-toolbar dark color="secondary">
      <v-toolbar-title>Login</v-toolbar-title>
      <!--      <v-img src="@/assets/logo-header.png" max-height="40" contain></v-img>-->
    </v-toolbar>
    <!--    <v-card-title class="primary mb-2">-->
    <!--      <v-img :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 80" contain src="@/assets/logo-header.png"></v-img>-->
    <!--    </v-card-title>-->
    <v-card-text>
      <v-alert
        type="error"
        v-if="error"
        v-text="error.message"
        dismissible
        transition="0.5"
        rounded
        elevation="0.2"
      ></v-alert>
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="login"
      >
        <v-text-field
          color="secondary"
          label="Username"
          prepend-icon="fas fa-user"
          v-model="user.username"
          required
          :rules="userNameRules"
        ></v-text-field>
        <v-text-field
          color="secondary"
          label="Password"
          prepend-icon="fas fa-lock"
          v-model="user.password"
          type="password"
          autocomplete="current-password"
          :rules="passwordRules"
        ></v-text-field>
        <v-checkbox
          color="secondary"
          v-model="user.saveLogin"
          light
          label="Stay logged in?"
          hide-details="hide-details"
        ></v-checkbox>
        <v-btn
          type="submit"
          class="rounded-0 mt-3"
          :loading="loading"
          dark
          color="secondary"
          block
          >Sign In</v-btn
        >
      </v-form>
    </v-card-text>
    <v-footer color="secondary" dark height="64">
      <!--      <v-toolbar-title>Data Proven Results</v-toolbar-title>-->
    </v-footer>
  </v-card>
</template>

<script>
import { lauderLarken } from "@/plugins/axios";
import storage from "@/storage";
import { MessageRequestError } from "@/errors";

export default {
  name: "login_handler",
  data() {
    return {
      loading: false,
      user: {
        username: "",
        password: "",
        saveLogin: true
      },
      error: false,
      userNameRules: [
        v => !!v || "Username is required",
        v => (v || "").indexOf(" ") < 0 || "No spaces are allowed"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v => (v || "").indexOf(" ") < 0 || "No spaces are allowed"
      ],
      valid: false
    };
  },
  methods: {
    async login() {
      if (await this.$refs.form.validate()) {
        this.loading = true;
        try {
          let result = await lauderLarken.login(this.user);
          let { token, mgmt } = result.data;
          if (token) {
            this.$store.commit("luadersetLoginState", true);
            // commit("luadersetLoginState", true);
            storage.setToken(token);
            storage.setMgmt(mgmt);
            this.$store.commit("luaderSetMgmt", mgmt);
            storage.setSession(new Date().getTime());
            this.$store.commit("loaderAppear", true);
            this.$store.commit("loaderText", "Collecting");
            if (mgmt) {
              this.$store.commit("lauderSetSelectedAss", {
                Client_Associations: "All Associations",
                AllAss: true
              });
              storage.setSelectedAss({
                Client_Associations: "All Associations",
                AllAss: true
              });
            } else {
              await this.$store.dispatch("lauderFetchUserData");
              this.$store.commit("lauderSetSelectedAss", {
                Client_Associations: this.filterAssName(this.$store.getters.laudergetLoginProfile.full_name)
              });
              storage.setSelectedAss({
                Client_Associations: this.filterAssName(this.$store.getters.laudergetLoginProfile.full_name)
              });
            }
            await this.$router.push({ name: "lauderLaw---Home" });
            this.$store.commit("loaderAppear", false);
          } else {
            throw new MessageRequestError();
          }
        } catch (e) {
          if (!e || e instanceof MessageRequestError) {
            this.error = { message: "Username or password is incorrect" };
          } else {
            this.error = e;
          }
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
